
/* --------- description list --------*/
[class*="dlist"]{
  margin-bottom:5px;
}

.dlist-inline{  
  dt, dd{ display:inline-block; }
}

.dlist{  display:flex;
  dt{  width: 150px;  font-weight:normal;}
  dd{ margin-left:30px; vertical-align: baseline; flex-grow:1; margin-bottom: 0; text-align:right; }
}
/* -------------------- list.row--------------------- */
ul.row, ul.row-sm{ list-style:none; padding:0; }

/* ================= lists ================= */
[class*="list-"] li:after {
  visibility: hidden;
  display: block;
  content: "";
  overflow: hidden; 
  height: 0; clear: both;
}

.list-icon { list-style:none; padding-left: 0; margin:0;
  li{margin-bottom:7px; position:relative; padding-left:30px; }
  .icon{width:22px; margin-right:15px; vertical-align:middle;  text-align: center; color:$secondary;
    position:absolute;  top:3px; left:0;
  }
  span{ vertical-align:middle}
}

.list-bullet{list-style: none; padding-left: 0; }
.list-bullet li{margin-bottom:5px; position:relative; padding-left:15px;}
.list-bullet li::before { 
    content: " ";
    position:absolute;  top:8px;  left:0;
    width: 7px; border-radius:100%;
    height: 7px;
    background:$primary;
    
}

.list-check{ padding-left: 0; list-style:none;
  > li{ position: relative; padding-left:24px; margin-bottom:7px;}
  > li::before {
       font-family: 'Font Awesome 5 Free';
       font-weight: 900; 
       font-size: 12px;
      color:$success;  position:absolute; top:2px; left:0; 
      content: "\f00c"; 
  }
}


.list-normal{ list-style: none; padding-left:0;
  li{ padding-left:1em; margin-bottom:7px; }
   li:before{ content: "\2022"; color: $primary;
      display: inline-block; width: 1em;
       margin-left: -1em
    }
}

.list-menu{ list-style: none; margin:0; padding-left:0;
  li{ margin-bottom:5px; }
  a{ color:$body-color;
    &:hover{ color: $primary }
  }
}

.cols-two{
  column-count:2;
}

.cols-three{
  column-count:3;
}

.cols-four{
  column-count:4;
}