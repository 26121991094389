.menu-aside{
	list-style:none;
	margin:$spacer;padding:0;
	
	a{ display:block;  text-decoration:none; font-family: $font-heading;font-weight: 600;}

	.menu-item{
		margin-bottom:5px;
		.icon{ color:$gray-500; margin-right:10px; font-size:24px;  }
		&.active{
			.icon{ color:$primary; }
			.menu-link{ background-color:$primary-light; }
			.submenu a.active {
				color: $brand !important;
			}
		}
	}

	.menu-link{
		padding:10px; 
		font-weight: 800;
		font-size: 13px;
		color:$color-menu; 
		border-radius:$border-radius-lg; 
		position:relative;
		line-height: 1;
		display: flex;
		align-items: center;
	}

	.menu-link:hover{
		transition:.2s linear; background-color:$gray-200;
	}

	.submenu{
		margin-left:44px;  display:none; margin-top: 10px;
		a{ 
			color:$gray-600; 
			padding:5px 0 5px 15px;
			transition-duration: 0.3s;
			position: relative;
			margin-left: 5px;
			&:hover{ 
				color:$black;
				transition-duration: 0.3s;
			}	
			&:before {
				content: "";
				width: 5px;
				height: 5px;
				position: absolute;
				background-color: $gray-500;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				border-radius: 50%;
			}
		}
	}

	.menu-item.active .submenu{ display:block; }

	// arrow triangle
	.menu-item.has-submenu > .menu-link:after{
		display: inline-block; 
		position:absolute;
		right: 10px; 
		top:15px;
		margin-top:.6em;
	    vertical-align:middle;
	    content: "";
	    border-top: 5px solid $gray-500;
	    border-right: 5px solid transparent;
	    border-bottom: 0;
	    border-left: 5px solid transparent;
	}

}


/// override bootstrap nav-pills
.nav-pills{
	.nav-link{ color:$gray-600;  font-weight:500;
		&:hover{ color:$dark;  background-color:rgba($gray-500, .15); }
	}
}
/*Pagination*/
.pagination-area {
	.page-item {
		margin: 0 5px;
		&:first-child {	
			margin-left: 0;		
			.page-link {
				border-top-left-radius: 50%;
				border-bottom-left-radius: 50%;
			}
		}
		&:last-child {
			.page-link {
				border-top-right-radius: 50%;
				border-bottom-right-radius: 50%;
			}
		}
		&.active , &:hover{
			.page-link {
				color: #fff;
				background: $brand;
			}
		}
		
	
		.page-link {
			border: 0;
			padding: 0 10px;
			box-shadow: none;
			outline: 0;
			width: 34px;
			height: 34px;
			display: block;
			border-radius: 4px;
			background: #e9ecee;
			line-height: 34px;
			text-align: center;
			font-size: 13px;
			color: $gray-700;
			&.dot {
				background-color: transparent;
				color: $gray-500;
				letter-spacing: 2px;
			}
			&:first-child {
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
			&:last-child {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}
	}
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
	color: $brand;
	background-color:$primary-light;
}