.card {
	margin-bottom: 24px;
	box-shadow: none;
  border: 1px solid #eee;
  border-radius: 10px;
  transition-duration: 0.3s;
  overflow: hidden;
  &:hover {
    transform: translateY(-2px);
    transition-duration: 0.3s;   
	  box-shadow: none;
  }
}

.icontext{
    display: inline-flex; align-items: top;
    .icon{position:relative;  flex-shrink: 0; margin-right:15px;
      &.border {max-height: 28px;}
    }
    small, .title{ display:block; } 
    span {
      font-size: 22px;
      font-weight: 600;
      display: block;
      &.text-sm {
        font-size: 12px;
        font-weight: 400;
        color: $gray-600;
      }
    }
}

.tag{
  display: inline-block; padding:3px 7px; background:#f2f2f2; border:1px xolid #eee; border-radius:3px; margin-top:4px; margin-right: 2px; font-size:85%;
}


.price{ font-weight: 600; }

.notify{
  position: absolute;
  top: -4px;  right: -10px;
  display: inline-block;
  padding: .25em .6em;
  font-size: 75%;  line-height: 1;  text-align: center;
  border-radius: 3rem;  color: #fff;  background-color:$red;
}


.map-pin{
  border-radius: 3rem; 
  background-color:rgb(255, 0, 0);
  position: absolute; 
  display:inline-block;
  width:16px; height:16px;
  &:after{
    content:"";  position: absolute; 
     width:50px; height:50px;
     display:inline-block;
     top:8px; left:8px;
     border-radius:100%; 
     transform:translate(-50%, -50%);
     background-color:rgba(255, 0, 0, .3);
  }
}

.icon-action{margin-top:5px; float:right;}


/* ====================== box ==================== */
.box{
  padding:$spacer; 
  border-radius:$border-radius; border:1px solid #eee; background:$white;
  img{ max-width: 100%; }
}
.border-bottom {
	border-bottom: 1px solid #eee !important;
}
/* ================= RATINGS ============== */
.label-rating{margin-left:7px; display:inline-block; vertical-align:middle}

/* rating-stars */
.rating-stars{display:inline-block; vertical-align:middle; list-style:none; margin:0; padding:0; position:relative; 
    white-space: nowrap; clear:both;  line-height:0.8;
    i{ font-size:14px; color:#ccc; display:inline;}
    img{ height: 12px; max-width:none;}
    li{display:block;  text-overflow:clip;  white-space:nowrap; z-index:1; }
  
    li.stars-active{z-index:2; position:absolute; top:0; left:0; overflow: hidden;
        i{color:orange;}
    }

    &.stars-lg{
      img{ height:24px; max-width:none;}
    }
}

/*dropdown*/
.dropdown-toggle {
  &:after {
    position: absolute;
    top: 50%;
    color: $gray-500;
  }
}
.dropdown-menu {
  min-width: 10rem;
  padding: .5rem 0;
  margin: 0;
  font-size: 13px;
  color: $gray-600;;
  text-align: left;
  list-style: none;
  background-color: #fff;
  border: 0;
  border-radius: .25rem;
  -webkit-box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  .dropdown-item {
    display: flex;
    width: 100%;
    padding: .35rem 1.5rem;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    white-space: nowrap;
    border: 0;
    align-items: center;
    i {
      margin-right: 10px;
      font-size: 16px;
      color: $gray-500;
    }
    img {
      max-width: 20px;
      margin-right: 10px;
    }
  }
}

.progress-bar {
  background-color: $brand;
}

.new-member-list {
  position: relative;
  img.avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
  }
}

.verti-timeline {
	border-left: 2px dashed #e1e1e1;
	margin: 0 10px;
	.event-list {
		position: relative;
		padding: 0 0 18px 30px;
    display: flex;
    align-items: center;
    &.active {
      .event-timeline-dot {
        color: $brand;
      }
    }		
		&:last-child {
			padding-bottom: 0;
		}
    .event-timeline-dot {
			position: absolute;
			left: -9px;
			top: 0;
			z-index: 9;
			font-size: 16px;
      color: $gray-600;      
		}
	}
}

.media {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: center;
  h6 {
    font-size: $font-sm;
    span {
      display: inline-block;
      min-width: 50px;
    }
    i {
      margin-left: 10px;
    }
  }
  .media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
/*BADGE*/

.badge[href]:focus,
.badge[href]:hover {
 color:#fff
}
.badge.bg-primary[href]:focus,
.badge.bg-primary[href]:hover {
 background-color:$brand!important
}
.badge.bg-light {
 color:#495057
}
.badge.bg-light[href]:focus,
.badge.bg-light[href]:hover {
 color:#495057
}
.badge-soft-primary {
 color:$brand;
 background-color:rgba(85, 230, 150, 0.18)
}
.badge-soft-primary[href]:focus,
.badge-soft-primary[href]:hover {
 color:$brand;
 text-decoration:none;
 background-color:rgba(85, 230, 150, 0.18)
}
.badge.bg-secondary[href]:focus,
.badge.bg-secondary[href]:hover {
 background-color:#6b6e82!important
}
.badge.bg-light {
 color:#495057
}
.badge.bg-light[href]:focus,
.badge.bg-light[href]:hover {
 color:#495057
}
.badge-soft-secondary {
 color:#74788d;
 background-color:rgba(116,120,141,.18)
}
.badge-soft-secondary[href]:focus,
.badge-soft-secondary[href]:hover {
 color:#74788d;
 text-decoration:none;
 background-color:rgba(116,120,141,.4)
}
.badge.bg-success[href]:focus,
.badge.bg-success[href]:hover {
 background-color:#30b383!important
}
.badge.bg-light {
 color:#495057
}
.badge.bg-light[href]:focus,
.badge.bg-light[href]:hover {
 color:#495057
}
.badge-soft-success {
 color:#34c38f;
 background-color:rgba(52,195,143,.18)
}
.badge-soft-success[href]:focus,
.badge-soft-success[href]:hover {
 color:#34c38f;
 text-decoration:none;
 background-color:rgba(52,195,143,.4)
}
.badge.bg-info[href]:focus,
.badge.bg-info[href]:hover {
 background-color:#3d9bef!important
}
.badge.bg-light {
 color:#495057
}
.badge.bg-light[href]:focus,
.badge.bg-light[href]:hover {
 color:#495057
}
.badge-soft-info {
 color:#50a5f1;
 background-color:rgba(80,165,241,.18)
}
.badge-soft-info[href]:focus,
.badge-soft-info[href]:hover {
 color:#50a5f1;
 text-decoration:none;
 background-color:rgba(80,165,241,.4)
}
.badge.bg-warning[href]:focus,
.badge.bg-warning[href]:hover {
 background-color:#f0ac39!important
}
.badge.bg-light {
 color:#495057
}
.badge.bg-light[href]:focus,
.badge.bg-light[href]:hover {
 color:#495057
}
.badge-soft-warning {
 color:#f1b44c;
 background-color:rgba(241,180,76,.18)
}
.badge-soft-warning[href]:focus,
.badge-soft-warning[href]:hover {
 color:#f1b44c;
 text-decoration:none;
 background-color:rgba(241,180,76,.4)
}
.badge.bg-danger[href]:focus,
.badge.bg-danger[href]:hover {
 background-color:#f35757!important
}
.badge.bg-light {
 color:#495057
}
.badge.bg-light[href]:focus,
.badge.bg-light[href]:hover {
 color:#495057
}
.badge-soft-danger {
 color:#f46a6a;
 background-color:rgba(244,106,106,.18)
}
.badge-soft-danger[href]:focus,
.badge-soft-danger[href]:hover {
 color:#f46a6a;
 text-decoration:none;
 background-color:rgba(244,106,106,.4)
}
.badge.bg-pink[href]:focus,
.badge.bg-pink[href]:hover {
 background-color:#e62c81!important
}
.badge.bg-light {
 color:#495057
}
.badge.bg-light[href]:focus,
.badge.bg-light[href]:hover {
 color:#495057
}
.badge-soft-pink {
 color:#e83e8c;
 background-color:rgba(232,62,140,.18)
}
.badge-soft-pink[href]:focus,
.badge-soft-pink[href]:hover {
 color:#e83e8c;
 text-decoration:none;
 background-color:rgba(232,62,140,.4)
}
.badge.bg-light[href]:focus,
.badge.bg-light[href]:hover {
 background-color:#e1e7f0!important
}
.badge.bg-light {
 color:#495057
}
.badge.bg-light[href]:focus,
.badge.bg-light[href]:hover {
 color:#495057
}
.badge-soft-light {
 color:#eff2f7;
 background-color:rgba(239,242,247,.18)
}
.badge-soft-light[href]:focus,
.badge-soft-light[href]:hover {
 color:#eff2f7;
 text-decoration:none;
 background-color:rgba(239,242,247,.4)
}
.badge.bg-dark[href]:focus,
.badge.bg-dark[href]:hover {
 background-color:#2b3035!important
}
.badge.bg-light {
 color:#495057
}
.badge.bg-light[href]:focus,
.badge.bg-light[href]:hover {
 color:#495057
}
.badge-soft-dark {
 color:#343a40;
 background-color:rgba(52,58,64,.18)
}
.badge-soft-dark[href]:focus,
.badge-soft-dark[href]:hover {
 color:#343a40;
 text-decoration:none;
 background-color:rgba(52,58,64,.4)
}
.rounded-pill {
 padding-right:.6em;
 padding-left:.6em
}
.badge.bg-dark {
 color:#eff2f7
}

.img-thumbnail {
	padding: 0.25rem;
	background-color: transparent;
	border: none;
	border-radius: 0.25rem;
	-webkit-box-shadow:  none;
	box-shadow:  none;
	max-width: 100%;
	height: auto;
}

/*ANIMATION*/
.animation-shake {
	-webkit-animation: shake 1.5s ease infinite;
	animation: shake 1.5s ease infinite;
}
@-webkit-keyframes shake {
  from {
   -webkit-transform:scale3d(1,1,1);
   transform:scale3d(1,1,1)
  }
  10%,
  20% {
   -webkit-transform:scale3d(.95,.95,.95) rotate3d(0,0,1,-10deg);
   transform:scale3d(.95,.95,.95) rotate3d(0,0,1,-10deg)
  }
  30%,
  50%,
  70%,
  90% {
   -webkit-transform:scale3d(1,1,1) rotate3d(0,0,1,10deg);
   transform:scale3d(1,1,1) rotate3d(0,0,1,10deg)
  }
  40%,
  60%,
  80% {
   -webkit-transform:scale3d(1,1,1) rotate3d(0,0,1,-10deg);
   transform:scale3d(1,1,1) rotate3d(0,0,1,-10deg)
  }
  to {
   -webkit-transform:scale3d(1,1,1);
   transform:scale3d(1,1,1)
  }
 }
 @keyframes shake {
  from {
   -webkit-transform:scale3d(1,1,1);
   transform:scale3d(1,1,1)
  }
  10%,
  20% {
   -webkit-transform:scale3d(.95,.95,.95) rotate3d(0,0,1,-10deg);
   transform:scale3d(.95,.95,.95) rotate3d(0,0,1,-10deg)
  }
  30%,
  50%,
  70%,
  90% {
   -webkit-transform:scale3d(1,1,1) rotate3d(0,0,1,10deg);
   transform:scale3d(1,1,1) rotate3d(0,0,1,10deg)
  }
  40%,
  60%,
  80% {
   -webkit-transform:rotate3d(0,0,1,-10deg);
   transform:rotate3d(0,0,1,-10deg)
  }
  to {
   -webkit-transform:scale3d(1,1,1);
   transform:scale3d(1,1,1)
  }
 }

 .animation-fade-right {
  -webkit-animation:fade-right 1.5s infinite linear;
  animation:fade-right 1.5s infinite linear
 }
 @-webkit-keyframes fade-right {
  0% {
   -webkit-transform:translateX(0);
   transform:translateX(0);
   opacity:1
  }
  75% {
   -webkit-transform:translateX(20px);
   transform:translateX(20px);
   opacity:0
  }
 }
 @keyframes fade-right {
  0% {
   -webkit-transform:translateX(0);
   transform:translateX(0);
   opacity:1
  }
  75% {
   -webkit-transform:translateX(20px);
   transform:translateX(20px);
   opacity:0
  }
 }